import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import "../scss/app.scss"
import TextImage from "../components/textImage"
// import GridImages from "../components/gridImages"
import BlockIntroduction from "../components/blockIntroduction"
import TitleTextCol from "../components/titleTextCol"
import Cta from "../components/cta"
import HeaderTwoImages from "../components/headerTwoImages"
import MetaImage from "../images/featured/loyall-featured-image-approach-light.jpg"

const OnzeAanpak = (props) => {
  return (
    <Layout>
      <Seo 
        title="Loyall – Onze digital lifecycle richt zich op de lange termijn" 
        image={{src: MetaImage, height: 628, width: 1200}}
        description="Korte termijn denkers willen zo snel mogelijk resultaat met hun product of dienst. Met de digital lifecycle focussen we ons op sterke relaties waardoorjouw organisatie op een duurzame manier kan groeien."
        pathname={props.location.pathname}    
      />
      <div className="page-wrapper">
        <BlockIntroduction
          title="Onze aanpak"
          intro={
            <h4>
              Alles wat we doen inspireert, lokt reactie uit, motiveert om deel te nemen en activeert een loyale community.
            </h4>
          }
          colWidth={{ lg: 8, md: 10 }}
        />
        <HeaderTwoImages
          imageBig={"loyall-our-approach-header-1.jpg"}
          imageSmall={"loyall-our-approach-header-2.jpg"}
          elementId="onze-aanpak-images"
          delay={200}
          animationDuration={600}
        />
        <TitleTextCol
          title="Producten en diensten hebben geen duurzame waarde als je geen betekenisvolle relaties opbouwt."
          paragh1="Korte termijn denkers willen producten en diensten op de markt brengen die zo snel mogelijk resultaat geven. Lange termijn denkers zien het diepe dal dat na die snelle piek komt. Succesvolle organisaties hebben die lange termijnvisie en kunnen rekenen op een trouwe klantenkring."
          paragh2="Met de digital lifecycle focussen we ons op het bouwen en onderhouden van die sterke relaties, zodat jouw organisatie op een duurzame manier kan groeien."
        />
        <TextImage
          title="Design"
          intro="Create to inspire"
          paragh="De eerste indruk die mensen van je bedrijf hebben is gericht op het uiterlijk van je online uitingen. Je website, socialmediakanalen of campagnes. Klopt je digitale identiteit met waar je bedrijf voor staat? Komt dit over op de doelgroep? De eerste fase van de lifecycle is om mensen te inspireren. En dat doen wij met design."
          link="/design"
          linkText="Design"
          classes="pizza-image"
          image={"loyall-lifecycle-inspiration-pizza.png"} 
          col={true}
        />
         <TextImage
          title="Marketing"
          intro="Evoke reaction"
          paragh="Met alleen mensen inspireren ben je er nog niet. Inspiratie vraagt namelijk niet om actie. Om reactie uit te lokken heb je waardevolle content nodig. Denk aan websites, blogs, social media of whitepapers. En hoe krijg je interesse voor deze content? Door campagnes te voeren en leads te verzamelen. En hoe krijg je fans van deze content? Door e-mail flows op te zetten die jouw leads op het juiste moment de juiste content toestuurt. Dat regelen wij met marketing."
          link="/marketing"
          linkText="Marketing"
          classes="pizza-image"
          image={"loyall-lifecycle-reaction-pizza.png"} 
          col={true}
        />
        <TextImage
          title="Development"
          intro="Accelerate together"
          paragh="Je website of applicatie is zo goed als de code. Je kunt aan de voorkant een prachtige website hebben, als het achter de schermen niet werkt loop je potentiële klanten mis. Wat is de performance van je website? Hoe zit het met de toegankelijkheid? Welke integraties kunnen je websites optimaliseren? Dat fixen we met development."
          link="/development"
          linkText="Development"
          classes="pizza-image"
          image={"loyall-lifecycle-motvation-pizza.png"} 
          col={true}
        />

        <TextImage
          title="Integraties"
          intro="Expanding connections"
          paragh="Hoe behoud je de connectie met je huidige en toekomstige klanten? Wat doe je met alle verzamelde data? Waar verzamel je eigenlijk data? Met integraties zorgen we ervoor dat je sales, marketing, HR en customer service vanaf één locatie kunt doen. Automatiseer je taken, maak dashboards met real-time data en biedt een uitmuntende service. Dit bereiken we met de juiste integraties."
          link="/integrations"
          linkText="Integraties"
          classes="pizza-image"
          image={"loyall-lifecycle-activation-pizza.png"} 
          col={true}
        />
        {/* <div className="text-center">
          <h2 style={{paddingLeft:15}}>Waar ligt jouw uitdaging?</h2>
          <GridImages 
              padding={{ top: 80, bottom: 80, left: 15, right: 15 }}

              images={[
                {
                  image: "loyall-lifecycle-full-pizza-2.png",
                  text: "",
                }
              ]}/>
        </div> */}
        <Cta
          verticalStyling={false} 
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
        />
      </div>
    </Layout>
  )
}
export default OnzeAanpak
