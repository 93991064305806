import * as React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHorizontalRule } from '@fortawesome/pro-regular-svg-icons'
import Image from "./elements/image"

const TextImage = ({id, title,titleLevelHeading=4,intro, classes="", paragh, paraghBigger=false,image, link, linkText, col=false,  padding={bottom:80, top:80,left:15,right:15}}) => {
  if(col) {
    return(
      <Container id={id} className={classes} style={{padding:`${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`}}>
        <Row className="align-items-center">
          <Col lg={6} className="text-wrapper">
            <p className="bolder opacity-40">
              {title}    
            </p>
            <h4 className="">
              {intro}    
            </h4>
            <p className={`${paraghBigger?"bigger":""}`}>
              {paragh}    
            </p>
            {
              link !== "" ? 
                <Link to={link} className="btn btn-solid btn-menu-item btn-loyall">
                  {linkText}
                  <FontAwesomeIcon icon={faHorizontalRule} />
                </Link>
              : null
            }
          </Col>
          <Col lg={6} className="image-wrapper">
            <Image fadeIn={true} imgsrc={image} />
          </Col>
        </Row>
      </Container>
    )
  }
  function handleTitle(){
    switch(titleLevelHeading){
      case 1:
        return <h1>{title}</h1>
      case 2:
        return <h2>{title}</h2>
      case 3:
        return <h3>{title}</h3>
      case 4:
        return <h4>{title}</h4> 
      case 5:
        return <h5>{title}</h5> 
      case 6:
        return <h6>{title}</h6> 
      default:
        break;
    }
  }
  return(
    <Container id={id} className={classes}  style={{padding:`${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`}}>
      <Row className="align-items-center">
        <Col lg={12} className="mb-5">
          <h4 className="">
          {handleTitle()}   
          </h4>
          <p className="">
            {paragh}    
          </p>
          {
            link !== "" ? 
              <Link to={link} className="btn btn-solid btn-menu-item btn-loyall">
                {linkText}
                <FontAwesomeIcon icon={faHorizontalRule} />
              </Link>
            : null
          }
        
        <Image fadeIn={true} imgsrc={image} />
        </Col>
      </Row>
    </Container>
  )
}

TextImage.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  intro: PropTypes.string,
  paragh: PropTypes.string,
  col: PropTypes.bool,
  padding: PropTypes.object,
}

export default TextImage