import * as React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from 'react-bootstrap';

const TitleTextCol = ({title, titleLevelHeading=3, columnSpan=6, paragh1=null, paragh2=null, bigParagraph=false, customContentColumn=null, padding={bottom:80, top:80,left:15,right:15}}) => {
  function handleTitle(){
    switch(titleLevelHeading){
      case 1:
        return <h1>{title}</h1>
      case 2:
        return <h2>{title}</h2>
      case 3:
        return <h3>{title}</h3>
      case 4:
        return <h4>{title}</h4> 
      case 5:
        return <h5>{title}</h5> 
      case 6:
        return <h6>{title}</h6> 
      default:
        break;
    }
  }

    
  return(
    <Container className="component title-text-col" style={{padding:`${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`}}>
      <Row>
        <Col md={12-columnSpan}>
          {handleTitle()}
        </Col>
        <Col md={columnSpan}>
          {customContentColumn !== null ? customContentColumn : null}
          {paragh1 !== null ? <p className={`${bigParagraph ? "biggest" : "bigger"}`}>{paragh1}</p> : null}
          {paragh2 !== null ? <p className={`${bigParagraph ? "biggest" : "bigger"}`}>{paragh2}</p>: null}
        </Col>
      </Row>
    </Container>
  )
  }
TitleTextCol.propTypes = {
 title: PropTypes.string,
 paragh1: PropTypes.string,
 paragh2: PropTypes.string,
 bigParagraph: PropTypes.bool,
 columnSpan: PropTypes.number,
 titleLevelHeading: PropTypes.number,
 padding: PropTypes.object,
}

export default TitleTextCol