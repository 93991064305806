import * as React from "react"
import PropTypes from "prop-types"
import {Row, Container, Col} from "react-bootstrap"
import Image from "./elements/image"
const HeaderTwoImages = ({imageBig, imageSmall, padding={bottom:80, top:80,left:15,right:15}, elementId, delay=0, animationDuration=0 }) =>{ 
return (
    <Container id={`${elementId}`} className="header-two-images" style={{padding:`${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`}}>
        <Row>
            <Col 
                xs={{ span: 12, order:1 }} 
                md={6}
                data-sal="slide-up"
                data-sal-delay={delay}
                data-sal-easing="easeOutCirc"
                data-sal-duration={animationDuration}
            >
                <Image fadeIn={true} imgsrc={imageBig} />
            </Col>
            <Col 
                xs={{ span: 12, order:2 }}  
                md={{span:4, offset: 2}} 
                data-sal="slide-up"
                data-sal-delay={delay}
                data-sal-easing="easeOutCirc"
                data-sal-duration={animationDuration}
            >
                <Image fadeIn={true} imgsrc={imageSmall} />
            </Col>
        </Row>
    </Container>
)
}
HeaderTwoImages.propTypes = {
  imageBig: PropTypes.string,
  imageSmall: PropTypes.string,
  padding: PropTypes.object
}

export default HeaderTwoImages