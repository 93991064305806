import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

export default class Image extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allImageSharp {
              edges {
                node {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        `}
        render={data => {
          if (typeof window !== "undefined") {
            return (
              <GatsbyImage
                alt={this.props.alt !== undefined ? this.props.alt : ``}
                image={
                  data.allImageSharp.edges.find(element => {
                    // Match string after final slash
                    return (
                      element.node.gatsbyImageData.images.fallback.src
                        .split("/")
                        .pop() === this.props.imgsrc
                    )
                  }).node.gatsbyImageData
                }
                loading={this.props.loading ? this.props.loading : "lazy"}
              />
            )
          }
        }}
      />
    )
  }
}
Image.propTypes = {
  fadeIn: PropTypes.bool,
  imgsrc: PropTypes.string,
}
